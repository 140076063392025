import styled from "styled-components";
import { FieldTimeOutlined, CloudDownloadOutlined, ForkOutlined, MessageOutlined } from "@ant-design/icons";
import { defaultColor, defaultFontSize } from "../../../../theme";
import { Row, Col } from "antd";

export const Advantage = () => {
  return (
    <div>
      <AdvantageBody>
        <h1>why should you use instally?</h1>

        <p>
          Lorem Ipsum is simply dummy text of the <br />
          printing and typesetting industry. Lorem Ipsum has been
          <br /> dummy text ever since the 1500s
        </p>
      </AdvantageBody>
      <AdvantageItems>
        <AdvantageTop>
          <Col sx={24} md={12} lg={12}>
            <ItemsDetails>
              <FirstItemIcon />
              <h4>Anylatics</h4>
              <p>Lorem Ipsum is simply dummy text of the printing</p>
            </ItemsDetails>
          </Col>
          <Col sx={24} md={12} lg={12}>
            <ItemsDetails>
              <FourthItemIcon />
              <h4>24/7 Support</h4>
              <p>Lorem Ipsum is simply dummy text of the printing</p>
            </ItemsDetails>
          </Col>
        </AdvantageTop>
        <AdvantageBottom>
          <Col sx={24} md={12} lg={12}>
            <ItemsDetails>
              <SecoundItemIcon />
              <h4>Secure connection</h4>
              <p>Lorem Ipsum is simply dummy text of the printing</p>
            </ItemsDetails>
          </Col>
          <Col sx={24} md={12} lg={12}>
            <ItemsDetails>
              <ThirdItemIcon />
              <h4>Cloud backup</h4>
              <p>Lorem Ipsum is simply dummy text of the printing</p>
            </ItemsDetails>
          </Col>
        </AdvantageBottom>
      </AdvantageItems>
    </div>
  );
};

const AdvantageBody = styled.div`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  h1 {
    font-weight: ${defaultFontSize.weight.eight};
    margin-bottom: 50px;
  }
  p {
    /* width: 470px; */
    text-align: center;
    padding: 25px;
    border-radius: 17px;
    line-height: 1.5;
    box-shadow: 1px 2px 18px ${defaultColor.shadow.gray};
    color: ${defaultColor.gray.middleGray};
    font-weight: ${defaultFontSize.weight.five};
    background: ${defaultColor.white};
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    p {
      padding: 25px;
    }
  }
  @media (max-width: 747px) {
    h1 {
      font-size: 21px;
    }
    p {
      text-align: unset;
    }
  }
`;

const AdvantageBottom = styled(Row)`
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  @media (min-width: 748px) and (max-width: 1023px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
`;

const AdvantageTop = styled(Row)`
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  @media (min-width: 748px) and (max-width: 1023px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
`;

const AdvantageItems = styled.div`
  @media (min-width: 748px) and (max-width: 1023px) {
    margin-top: 20px;
  }
`;
const ItemsDetails = styled.div`
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    margin-bottom: 0px;
  }
  p {
    font-size: ${defaultFontSize.size.smaller};
    color: ${defaultColor.gray.middleGray};
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 747px) {
    margin: 30px;
  }
`;

const FirstItemIcon = styled(FieldTimeOutlined)`
  color: ${defaultColor.purple.darkPurple};
  font-size: 40px;
  background: ${defaultColor.purple.lightPurple};
  border-radius: 52px;
  padding: 11px;
  align-items: center;
  text-align: center;
`;

const SecoundItemIcon = styled(CloudDownloadOutlined)`
  color: #ff8c42;
  font-size: 40px;
  background: rgb(255 221 174);
  border-radius: 52px;
  padding: 11px;
  align-items: center;
  text-align: center;
`;

const ThirdItemIcon = styled(ForkOutlined)`
  color: #00b4d8;
  font-size: 40px;
  background: #caf0f8;
  border-radius: 52px;
  padding: 11px;
  align-items: center;
  text-align: center;
`;

const FourthItemIcon = styled(MessageOutlined)`
  color: #57cc99;
  font-size: 40px;
  background: #c7f9cc;
  border-radius: 52px;
  padding: 11px;
  align-items: center;
  text-align: center;
`;
