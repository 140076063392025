import React, { useState } from "react";
import styled from "styled-components";
import { defaultColor, defaultFontSize } from "../../../theme";
import { Link } from "react-router-dom";
import { Drawer } from "antd";
import { Col, Row } from "antd";
import { AlignLeftOutlined } from "@ant-design/icons";
import useMediaQuery from "../use.media.query/use.media.query";

export const Header = () => {
  const [click, setClick] = useState(5);
  // const [isMobile, setIsMobile] = useState(true);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const isMobile = useMediaQuery("(max-width: 747px)");

  return (
    <HeaderCol>
      <Col span={24}>
        {isMobile ? (
          <>
            <AlignLeftOutlined onClick={showDrawer} style={{ fontSize: "x-large" }} />
            <HeaderDrawer placement="left" onClose={onClose} open={open}>
              <NavVertical>
                <h3>INSTALLY</h3>
                <HeaderHr />
                <NavVerticalButton>
                  <NavBodyLink to="/contactus">
                    <NavWhiteButton onClick={() => setClick(1)} isActive={click === 1}>
                      Contact Us
                    </NavWhiteButton>
                  </NavBodyLink>
                  <NavBodyLink to="/price">
                    <NavWhiteButton onClick={() => setClick(2)} isActive={click === 2}>
                      Pricing
                    </NavWhiteButton>
                  </NavBodyLink>
                  <NavBodyLink to="/login">
                    <NavWhiteButton onClick={() => setClick(3)} isActive={click === 3}>
                      Log in
                    </NavWhiteButton>
                  </NavBodyLink>
                  <NavBodyLink to="/document">
                    <NavWhiteButton onClick={() => setClick(4)} isActive={click === 4}>
                      Document
                    </NavWhiteButton>
                  </NavBodyLink>
                  <NavBodyLink to="/">
                    <NavWhiteButton onClick={() => setClick(0)} isActive={click === 0}>
                      Home
                    </NavWhiteButton>
                  </NavBodyLink>
                </NavVerticalButton>
              </NavVertical>
            </HeaderDrawer>
          </>
        ) : (
          <NavBody>
            <h3>INSTALLY</h3>
            <div>
              <Link to="/contactus">
                <NavWhiteButton onClick={() => setClick(1)} isActive={click === 1}>
                  Contact Us
                </NavWhiteButton>
              </Link>
              <Link to="/price">
                <NavWhiteButton onClick={() => setClick(2)} isActive={click === 2}>
                  Pricing
                </NavWhiteButton>
              </Link>
              <Link to="/login">
                <NavWhiteButton onClick={() => setClick(3)} isActive={click === 3}>
                  Log in
                </NavWhiteButton>
              </Link>
              <Link to="/document">
                <NavWhiteButton onClick={() => setClick(4)} isActive={click === 4}>
                  Document
                </NavWhiteButton>
              </Link>
              <Link to="/">
                <NavPurpleButton onClick={() => setClick(0)} isActive={click === 0}>
                  Home
                </NavPurpleButton>
              </Link>
            </div>
          </NavBody>
        )}
      </Col>
    </HeaderCol>
  );
};

const HeaderHr = styled.hr`
  height: 1px;
  background-color: #dcb9ff;
  width: 82%;
  border: none;
  margin-bottom: 20px;
`;

const HeaderDrawer = styled(Drawer)`
  width: 80% !important;
  backdrop-filter: blur(3px);
  .ant-drawer-body {
    padding: 0px;
  }
  .ant-drawer-content-wrapper {
    backdrop-filter: blur(3px) !important;
  }
  .ant-drawer.ant-drawer-left.css-dev-only-do-not-override-htwhyh.ant-drawer-open {
    backdrop-filter: blur(3px) !important;
  }
  .ant-drawer-mask {
    backdrop-filter: blur(3px) !important;
  }
  (.css-dev-only-do-not-override-htwhyh).ant-drawer-left>.ant-drawer-content-wrapper {
    backdrop-filter: blur(3px) !important;
  }
  .ant-drawer-header.ant-drawer-header-close-only {
    display: none;
  }
`;

const NavVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  @media (min-width: 748px) and (max-width: 1023px) {
    width: 100%;
  }
  h3 {
    font-weight: 900;
    font-size: x-large;
    color: purple;
    margin: 32px 0px 26px 18px;
    color: ${defaultColor.purple.darkPurple};
  }
`;

const NavVerticalButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  @media (max-width: 747px) {
    width: 100%;
  }
`;

const HeaderCol = styled(Row)`
  @media (max-width: 747px) {
    .ant-col.ant-col-24.css-dev-only-do-not-override-htwhyh {
      background: #7114cc;
      height: 45px;
      color: white;
      text-align: left;
      padding: 10px;
    }
  }
`;

const NavBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 80px 0px;
  @media (min-width: 748px) and (max-width: 1023px) {
    padding: 20px 50px 0px;
  }
  h3 {
    font-weight: ${defaultFontSize.weight.nine};
    font-size: ${defaultFontSize.size.big};
    color: ${defaultColor.purple.darkPurple};
  }
  @media (max-width: 747px) {
    padding: 20px 50px 0px;
    Link {
      width: 85%;
    }
  }
`;

const NavBodyLink = styled(Link)`
  @media (max-width: 747px) {
    width: 83%;
  }
`;

const NavWhiteButton = styled.button`
  border: none;
  background: none;
  border-radius: 5px;
  background: ${({ isActive }) => (isActive ? `${defaultColor.gray.lightGray} ` : "")};
  margin: 0px 7px;
  padding: 10px 17px;
  font-size: ${defaultFontSize.size.inherit};
  :hover {
    background: ${defaultColor.gray.lightGray};
    border-radius: 5px;
    cursor: pointer;
  }
  @media (max-width: 747px) {
    font-size: large;
    width: 100%;
    text-align: start;
    margin-bottom: 5px;
    background: ${({ isActive }) => (isActive ? `${defaultColor.gray.lightGray} ` : "")};
    padding: 13px;
    :hover {
      width: 100%;
      padding: 13px;
      border-radius: 8px;
      /* width: 180px; */
      text-align: start;
      margin-bottom: 5px;
      background: ${defaultColor.gray.lightGray};
    }
  }
`;

const NavPurpleButton = styled.button`
  background: ${({ isActive }) => (isActive ? `${defaultColor.purple.darkPurple} ` : `  ${defaultColor.purple.middlePurple}`)};
  color: ${defaultColor.white};
  box-shadow: ${({ isActive }) => (isActive ? `0px 7px 9px ${defaultColor.shadow.inputPurpleShadow} ` : "")};
  padding: 8px 14px;
  border-radius: 5px;
  border: none;
  margin-left: 8px;
  cursor: pointer;
  font-size: ${defaultFontSize.size.inherit};
  :hover {
    background: ${defaultColor.purple.darkPurple};
    box-shadow: 0px 7px 9px ${defaultColor.shadow.inputPurpleShadow};
  }
`;
