import React from "react";
import styled from "styled-components";
import { defaultFontSize, defaultColor } from "../../../../theme";
import content1 from "./../../../../img/content1.png";
import content2 from "./../../../../img/content2.png";
import content3 from "./../../../../img/content3.png";
import content4 from "./../../../../img/content4.png";
import content5 from "./../../../../img/content5.png";
import content6 from "./../../../../img/content6.png";
import content7 from "./../../../../img/content7.png";
import content8 from "./../../../../img/content8.png";
import bg1 from "../../../../img/bg1.png";
import { Row, Col } from "antd";

export const Content = () => {
  return (
    <div>
      <PurpleCircle src={bg1} />
      <SuitableBody>
        <Row>
          <Col span={24}>
            {" "}
            <h1>Content</h1>
          </Col>
        </Row>
        <Row>
          <SuitableItems>
            <Col xs={24} md={24} lg={12}>
              <SuitableItemsDetail>
                <h2>Direct iOS App Installations:</h2>
                <p>
                  Installi offers direct iOS app installations for enterprise and in-house apps, allowing you to bypass the App Store and quickly
                  install apps on your iOS devices.
                </p>
              </SuitableItemsDetail>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <img src={content1} />
            </Col>
          </SuitableItems>
        </Row>
        <Row>
          <SuitableItemsRevers>
            <Col xs={24} md={24} lg={12}>
              <SuitableItemsDetail>
                <h2>Developer Account Integration:</h2>
                <p>
                  Integrate your Apple Developer Account with Installi to streamline the process of managing and distributing your apps to your
                  customers' devices. Simply add your account to Installi's client panel, and you're ready to go.
                </p>
              </SuitableItemsDetail>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <img src={content2} />
            </Col>
          </SuitableItemsRevers>
        </Row>
        <Row>
          <SuitableItems>
            <Col xs={24} md={24} lg={12}>
              <SuitableItemsDetail>
                <h2>Fast and Secure App Distribution:</h2>
                <p>
                  With Installi, you can quickly and securely distribute your apps to your customers' iOS devices using the UDID-based installation
                  method. This process is fast, easy, and ensures that your apps are installed on authorized devices only.
                </p>
              </SuitableItemsDetail>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <img src={content3} />
            </Col>
          </SuitableItems>
        </Row>
        <LeftPurpleCircle></LeftPurpleCircle>
        <Row>
          <SuitableItemsRevers>
            <Col xs={24} md={24} lg={12}>
              <SuitableItemsDetail>
                <h2>Comprehensive App Management:</h2>
                <p>
                  Installi's web-based dashboard provides you with complete control over your app distribution process, allowing you to manage your
                  customer devices, track app installations, and monitor app usage. This way, you can ensure that your apps are being used effectively
                  and efficiently.
                </p>
              </SuitableItemsDetail>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <img src={content4} />
            </Col>
          </SuitableItemsRevers>
        </Row>
        <Row>
          <SuitableItems>
            <Col xs={24} md={24} lg={12}>
              <SuitableItemsDetail>
                <h2>Customized App Branding:</h2>
                <p>
                  With Installi, you can customize the app branding to match your company's branding. You can add your company's logo, change the app
                  name and icon, and personalize the app's interface to create a unique app experience for your customers.
                </p>
              </SuitableItemsDetail>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <img src={content5} />
            </Col>
          </SuitableItems>
        </Row>
        <Row>
          <SuitableItemsRevers>
            <Col xs={24} md={24} lg={12}>
              <SuitableItemsDetail>
                <h2>Multiple App Versions:</h2>
                <p>
                  Installi supports multiple app versions, allowing you to distribute different versions of your app to different customer devices
                  simultaneously. This feature ensures that your customers have access to the latest version of your app, and it makes it easy to test
                  new app versions before releasing them to the public.
                </p>
              </SuitableItemsDetail>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <img src={content6} />
            </Col>
          </SuitableItemsRevers>
        </Row>
        <Row>
          <SuitableItems>
            <Col xs={24} md={24} lg={12}>
              <SuitableItemsDetail>
                <h2>Real-Time App Installations Status:</h2>
                <p>
                  Installi provides real-time updates on the status of your app installations. This feature allows you to track the progress of your
                  app installations and resolve any issues that may arise quickly.
                </p>
              </SuitableItemsDetail>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <img src={content7} />
            </Col>
          </SuitableItems>
        </Row>
        <Row>
          <SuitableItemsRevers>
            <Col xs={24} md={24} lg={12}>
              <SuitableItemsDetail>
                <h2>Efficient App Deployment:</h2>
                <p>
                  Installi makes app deployment easy and efficient. You can distribute your apps to multiple devices with just a few clicks, and the
                  web-based dashboard provides you with a centralized platform to manage all of your app installations. This way, you can save time
                  and resources while ensuring that your apps are installed correctly and securely.
                </p>
              </SuitableItemsDetail>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <img src={content8} />
            </Col>
          </SuitableItemsRevers>
        </Row>
      </SuitableBody>
    </div>
  );
};

const PurpleCircle = styled.img`
  position: absolute;
  right: 0px;
  margin-top: 200px;
`;

const LeftPurpleCircle = styled.div`
  width: 800px;
  height: 700px;
  background: ${defaultColor.purple.lightestPurple};
  border-radius: 0px 1000px 600px 1000px;
  margin-top: -50px;
  border: none;
  position: absolute;
  left: -514px;
  rotate: 347deg;
`;

const SuitableBody = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  h1 {
    font-weight: 800;
  }
`;

const SuitableItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0px;
  position: relative;
  img {
    width: 90% !important;
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 80px;
  }
  img {
    width: 90%;
  }
  @media (max-width: 747px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 40px;
  }
  img {
    width: 90%;
  }
`;

const SuitableItemsRevers = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0px;
  position: relative;
  img {
    width: 90% !important;
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 80px;
  }
  img {
    width: 90%;
  }
  @media (max-width: 747px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 40px;
  }
  img {
    width: 90%;
  }
`;

const SuitableItemsDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: initial;
  h2 {
    font-weight: ${defaultFontSize.weight.eight};
  }
  p {
    line-height: 1.5;
    color: ${defaultColor.gray.middleGray};
    font-weight: ${defaultFontSize.weight.five};
    margin-bottom: 22px;
  }
  button {
    background: ${defaultColor.purple.darkPurple};
    color: ${defaultColor.white};
    padding: 8px 14px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: ${defaultFontSize.size.inherit};
  }
  @media (max-width: 747px) {
    h2 {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 2px;
    }
  }
`;
